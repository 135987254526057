<!-- roles -->
<template>
  <GgPage pageType="2" title="Roles">
    <template v-slot:left>
      <div class="mar-l-b">
        <gg-create-button @click="createRole" label="Create role" />
      </div>
      <gg-flex-menus
        :menus="menuDatas"
        @select="menuSelect"
        :default-active="activeIndex"
      />
    </template>
    <edoovo-table
      height="100%"
      :ref="usersData.ref"
      :data="usersData.tableData"
      rowKey="id"
      :title="usersData.tableTitle"
      defaultValue="-"
      class="list-tableBox"
      v-loading="usersData.isLoading"
      @sortChange="_onSortChangeUser"
      :sort="usersData.sort"
    >
      <!-- ===== -->
      <!-- 空状态 -->
      <!-- ===== -->
      <div slot="empty" style="text-align: center">
        <div
          style="
            width: 213px;
            text-align: center;
            border-radius: 50%;
            margin-bottom: 12px;
            line-height: 200px;
          "
        >
          <img
            style="width: 100%"
            :src="require('@/assets/images/products/noData.svg')"
          />
        </div>
        <p style="font-size: 22px">No Roles yet.</p>
      </div>
      <!-- ======= -->
      <!-- 分页组件 -->
      <!-- ======= -->
      <edoovo-pagination
        slot="pagination"
        @size-change="_pageSizeChange"
        @current-change="_pageChange"
        :current-page="query.current"
        :page-size="query.size"
        :total="usersData.totalNum"
      />
      <!-- ====== -->
      <!-- 筛选组件 -->
      <!-- ====== -->
      <div v-if="filterRenderData.length" class="list-filterBox" slot="filter">
        <base-filter
          ref="tablefilter"
          @filterCallback="_filterCallback"
          :renderData="filterRenderData"
          :multKeys="multKeys"
        />
      </div>
      <!-- =========== -->
      <!-- 每一项column -->
      <!-- =========== -->
      <edoovo-table-column
        size="L"
        label="Role"
        class="table-cell-box"
        prop="name"
        sortName="name"
        :tooltip="true"
        v-slot="{ row }"
        @click="linkTo"
      >
        {{ row.name }}
      </edoovo-table-column>
      <edoovo-table-column
        size="L"
        label="Description"
        prop="describ"
      />
      <edoovo-table-column
        size="M"
        label="Type"
        prop="type"
        v-slot="{ row }"
        :tooltip="true"
        :getTooltipText="(row) => `${row.type === '0' ? 'System role' : 'Custom role'}`"
      >
        {{ row.type === "0" ? "System role" : "Custom role" }}
      </edoovo-table-column>
      <!-- ====== -->
      <!-- action -->
      <!-- ====== -->
      <edoovo-table-column v-slot="{ row }" key="action">
        <template v-if="row.status === 'active'">
          <el-tooltip
            content="Edit role"
            popper-class="edoovo-table-cell-tooltip"
            :visible-arrow="false"
			v-if="row.type != 0"
          >
            <gg-submit-button @click="openFormDialog('edit', row)">
              Edit
            </gg-submit-button>
          </el-tooltip>
          <el-tooltip
            content="Duplicate role"
            popper-class="edoovo-table-cell-tooltip"
            :visible-arrow="false"
          >
            <gg-submit-button @click="openFormDialog('duplicate', row)">
              Duplicate
            </gg-submit-button>
          </el-tooltip>
          <el-tooltip
            :content="row.using ? 'Cannot delete if role is in use' : 'Delete role'"
            popper-class="edoovo-table-cell-tooltip"
            :visible-arrow="false"
			v-if="row.type != 0"
          >
            <div style="margin-left: 10px">
              <gg-submit-button :disabled="row.using || row.type ==='0'" @click="handleDelete(row)">
                Delete
              </gg-submit-button>
            </div>
          </el-tooltip>
        </template>
      </edoovo-table-column>
    </edoovo-table>
    <BaseFormDialog1
      v-model="formDialogAdd.visible"
      :title="formDialogAdd.title"
      :data="formDialogAdd.dataList"
      :renderPass="formDialogAdd.renderPass"
      @submit="handleAddForm"
      textReminder="* indicates a required field"
      cancelBtnTxt="Cancel"
      :submitBtnTxt="formDialogAdd.submitBtnTxt"
    >
      <template slot="renderHtml" slot-scope="scope">
        <gg-input
          v-if="scope.data.key === 'name'"
          :key="scope.data.key"
          :ref="scope.data.key"
          v-model="scope.data.value"
          type="input"
          :placeholder="scope.data.placeholder"
          :error="errorName"
        />
      </template>
    </BaseFormDialog1>
    <Snackbar
      :visible.sync="snackbarData.visible"
      :content="snackbarData.content"
    />
  </GgPage>
</template>

<script>
import { Ajax } from "@/common";
import { mapState } from "vuex";
export default {
  name: "roles",
  components: {},
  computed:{
    errorName(){
      let nameVal = this.formDialogAdd.dataList.find(
        (item) => item.key === "name"
      ).value;

      if (nameVal === "") {
        return {
          show: true,
          message: "Role name required",
        };
      }

      let isPass = nameVal.length < 40;
      return {
        message: "Character limit of 80",
        show: !isPass,
      };
    }
  },
  watch: {
    formDialogAdd: {
      deep: true,
      handler(res) {
        this.$nextTick(() => {
          res.renderPass = !this.$refs.name.error.show
          console.log(res.renderPass);
        })
      }
    }
  },
  data() {
    return {
      menuDatas: [
        {
          name: "Roles",
          key: "",
          active: true,
          children: [
            {
              name: "System role",
              key: "0",
            },
            {
              name: "Custom role",
              key: "1",
            },
          ],
        },
      ],
      activeIndex: "",
      query: {
        size: 20,
        current: 1,
        type: "",
      },
      usersData: {
        ref: "usersTable",
        totalNum: 0,
        sort: { name: "name", type: "desc" },
        isLoading: false,
        tableData: [],
        tableTitle: "Roles",
      },
      filterRenderData: [
        { filterKey: "name", type: "input", title: "Role name" },
        { filterKey: "describ", type: "input", title: "Description" },
      ],
      multKeys: [],
	  rolePermissionList:[],
      formDialogAdd: {
        visible: false,
        title: "",
        duplicate: "Duplicate role",
        edit: "Edit role info",
        submitBtnTxt: "Save",
        renderPass: false,
        dataList: [
          {
            placeholder: "Name *",
            key: "name",
            value: "",
            renderHtml: true,
            // reg: /\S/,
            // errorText: "Character limit of 20",
          },
          {
            placeholder: "Description",
            key: "describ",
            value: "",
            reg: /^[\s\S]{0,80}$/,
            errorText: "describ limit of 80",
          },
        ],
      },
      snackbarData: {
        visible: false,
        content: "",
      },
    };
  },
  methods: {

    handleSnack(content) {
      this.snackbarData.content = content;
      this.snackbarData.visible = true;
    },
    createRole() {
      this.$router.push("/createRoles");
    },
    openFormDialog(type, row) {
      switch (type) {
        case "duplicate":
          this.formDialogAdd.title = this.formDialogAdd.duplicate;
          this.formDialogAdd.submitBtnTxt = "Duplicate";
          this.formDialogAdd.id = row.id;
          this.formDialogAdd.dataList.forEach((i) => {
            i.value = row[i.key];
            if (i.key === "name") {
              i.value = `Copy of ${row[i.key]}`;
            }
          });
          break;
        case "edit":
          this.formDialogAdd.title = this.formDialogAdd.edit;
          this.formDialogAdd.submitBtnTxt = "Save";
          this.formDialogAdd.id = row.id;

          this.formDialogAdd.dataList.forEach((i) => {
            i.value = row[i.key];
          });
          break;
      }
      this.formDialogAdd.id = row.id;
      this.formDialogAdd.visible = true;
    },
    _onSortChangeUser(sort) {
      // this.usersData.sort = sort;
      this.usersData.sort = sort;
      this.query.sortName = sort.name;
      this.query.sortType = sort.type;
      // console.log(sort);
      this.getData();
    },
    _pageChange(val) {
      this.query.current = val;
      this.getData();
    },
    _pageSizeChange(val) {
      this.query.size = val;
      this.getData();
    },
    _filterCallback(data) {
      let obj = {};
      if (data.length == 0) {
        this.query = {
          size: 20,
          current: 1,
          type: this.activeIndex,
        };
      }

      data.filter((i) => {
        obj[i.filterKey] = i.filterVal;
      });

      this.query = {
        size: 20,
        current: 1,
        type: this.activeIndex,
      };
      this.query = Object.assign(obj, this.query);
      this.getData();
    },
    menuSelect(key, item) {
      this.activeIndex = key;
      this.query.type = item.key;
      this.usersData.tableTitle = item.name;
      this.getData();
      if (key === "Roles") {
        this.$router.push({ name: "userRoles" });
      }
    },
    getData() {
      this.query.userId = this.$store.state.user.userId;
      this.usersData.isLoading = true;
      Ajax.post("/usermanage/role/pageMoreList", this.query)
        .then((res) => {
          console.log(res);
          if (res.code === "0000") {
            this.usersData.isLoading = false;
            let { records, total } = res.data;
            this.usersData.tableData = records;
            this.usersData.totalNum = total;
          }
        })
        .catch((err) => {
          this.usersData.isLoading = false;
        });
    },
    handleAddForm() {
      if (this.formDialogAdd.title === this.formDialogAdd.edit) {
        this.handleEditSubmit();
      } else if (this.formDialogAdd.title === this.formDialogAdd.duplicate) {
        this.handleDuplicateSubmit();
      } else {
        this.handleAddSubmit();
      }
    },
	getPermissionList(){
		
		let formData = {};
		this.formDialogAdd.dataList.forEach(item=>{
		  formData[item.key] = item.value
		})
		formData.id = this.formDialogAdd.id;
		formData.rolePermissionList=this.rolePermissionList
		Ajax.post("/usermanage/role/update",formData)
		.then((res) => {
		  if (res.code === "0000") {
		    this.handleSnack("Role info has been edited");
		    this.getData();
		  } else {
		    this.handleSnack(res.message);
		  }
		})
	},
    handleEditSubmit() {
		Ajax.get("/usermanage/role/selectRoleDetailByRoleId", {
		  roleId: this.formDialogAdd.id,
		}).then((res) => {
		  if (res.code === "0000") {
		    this.rolePermissionList = res.data.rolePermissionList;
			this.getPermissionList()
		  }
		});
    
    },
    handleAddSubmit() {
      let formData = {};
      this.formDialogAdd.dataList.forEach(item=>{
        formData[item.key] = item.value
      })

      formData.userId = this.$store.state.user.userId;
      formData.id = this.formDialogAdd.id;

      Ajax.post("/usermanage/role/save",formData)
      .then((res) => {
        if (res.code === "0000") {
          this.handleSnack("Role has been added");
          this.getData();
        } else {
          this.handleSnack(res.message);
        }
      })
    },
    handleDuplicateSubmit(){
      let formData = {};
      this.formDialogAdd.dataList.forEach(item=>{
        formData[item.key] = item.value
      })

      formData.userId = this.$store.state.user.userId;
      formData.id = this.formDialogAdd.id;
      Ajax.post("/usermanage/role/duplicate",formData)
      .then((res) => {
        if (res.code === "0000") {
          this.handleSnack("Role has been duplicated");
          this.getData();
        } else {
          this.handleSnack(res.message);
        }
      })
    },
    handleDelete(row) {
      this.$confirm(
        "Once the role is deleted, you will not be able to recover it.",
        "Delete role ?",
        {
          cancelButtonText: "Cancel",
          confirmButtonText: "Delete",
          cancelButtonClass: "gg_cancel_button",
          confirmButtonClass: "gg_submit_button",
          customClass: "gg_confirm",
          showClose: false
        }
      )
        .then(() => {
          Ajax.get("/usermanage/role/delete", { id: row.id }).then(
            (res) => {
              if (res.code === "0000") {
                this.handleSnack("Role has been deleted");
                this.getData();
              } else {
                this.handleSnack(res.message);
              }
            }
          );
        })
        .catch(() => {});
    },
    linkTo(row) {
      this.$router.push({
        name: "editRoles",
        query: { id: row.row.id },
      });
    },
  },
  created() {
    this.getData();
  },
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
</style>