<!-- create role -->
<template>
  <div class="content has_breadcrumb">
    <base-breadcrumb
      :data="[
        {
          routeName: 'companyRoles',
          name: 'Roles',
        },
        {
          name: 'Create new role',
        },
      ]"
      slot="breadcrumb"
    />
    <GgPage pageType="1" title="Roles">
      <div class="roles-content mar-b-20">
        <Section title="Role info">
          <template v-for="item in fromDataInfo">
            <gg-input
              :key="item.key"
              :ref="item.key"
              v-model="item.value"
              filedType="input"
              type="input"
              :placeholder="item.placeholder"
              :error="errorObjCom[item.key]"
            />
          </template>
          <p class="text-reminder">* indicates a required field</p>
        </Section>
        <Section title="Privileges" class="section-nopad">
          <ul class="privileges-default">
            <li v-for="(item, index) in formDataPrivilege" :key="index">
              <div class="privileges-default-header">
                <img
                  class="privileges-default-header-icon"
                  :src="item.apps"
                  alt=""
                />
                <i class="iconfont iconarrow_drop_down-24px" />
                <md-checkbox
                  class="md-primary"
                  v-model="item.ismodulePermission"
                  >{{ item.moduleName }}</md-checkbox
                >
              </div>
              <ul class="privileges-default-list">
                <li
                  v-for="(privilege, subIndex) in item.privilegeList"
                  :key="subIndex"
                >
                  <div class="privileges-default-space"></div>
                  <div class="privileges-default-role">
                    {{ privilege.name }}
                  </div>
                  <div
                    class="privileges-default-describcontent"
                    v-if="item.ismodulePermission"
                  >
                    <div class="privileges-default-privilege">
                      <baseGGSelect
                        v-model="privilege.optionsVal"
                        :inline="true"
                      >
                        <md-option
                          v-for="option in privilege.options"
                          :key="option"
                          :value="option"
                        >
                          {{ option }}
                        </md-option>
                      </baseGGSelect>
                    </div>
                    <el-tooltip
                      v-if="privilege.optionsVal === 'View'"
                      :content="`${privilege.moduleDesc['View']}`"
                      popper-class="edoovo-table-cell-tooltip"
                      :visible-arrow="false"
                    >
                      <div class="privileges-default-desc">
                        {{ `${privilege.moduleDesc["View"]}` }}
                      </div>
                    </el-tooltip>
                    <el-tooltip
                      v-else-if="privilege.optionsVal === 'Create'"
                      :content="`${privilege.moduleDesc['View']}, ${privilege.moduleDesc['Create']}`"
                      popper-class="edoovo-table-cell-tooltip"
                      :visible-arrow="false"
                    >
                      <div class="privileges-default-desc">
                        <!-- {{`${privilege.moduleDesc["View"]}, ${privilege.moduleDesc["Create"]}`}} -->
						{{
						  `${privilege.moduleDesc["Create"]?`${privilege.moduleDesc["View"]}, ${privilege.moduleDesc["Create"]}`:`${privilege.moduleDesc["View"]}`}`
						}}
                      </div>
                    </el-tooltip>
                    <el-tooltip
                      v-else-if="privilege.optionsVal === 'Edit'"
                      :content="`${privilege.moduleDesc['View']}, ${privilege.moduleDesc['Create']}, ${privilege.moduleDesc['Edit']}`"
                      popper-class="edoovo-table-cell-tooltip"
                      :visible-arrow="false"
                    >
                      <div class="privileges-default-desc">
                      <!--  {{
                          `${privilege.moduleDesc["View"]}, ${privilege.moduleDesc["Create"]}, ${privilege.moduleDesc["Edit"]}`
                        }} -->
						{{
						  `${privilege.moduleDesc["Create"]?`${privilege.moduleDesc["View"]}, ${privilege.moduleDesc["Create"]}, ${privilege.moduleDesc["Edit"]}`
						  :`${privilege.moduleDesc["View"]}, ${privilege.moduleDesc["Edit"]}`}`
						}}
                      </div>
                    </el-tooltip>
                    <el-tooltip
                      v-else-if="privilege.optionsVal === 'Full'"
                      :content="`${privilege.moduleDesc['View']}, ${privilege.moduleDesc['Create']}, ${privilege.moduleDesc['Edit']}, ${privilege.moduleDesc['Full']}`"
                      popper-class="edoovo-table-cell-tooltip"
                      :visible-arrow="false"
                    >
                      <div class="privileges-default-desc">
                     <!--   {{
                          `${privilege.moduleDesc["View"]}, ${privilege.moduleDesc["Create"]}, ${privilege.moduleDesc["Edit"]}, ${privilege.moduleDesc["Full"]}`
                        }} -->
						{{
						  `${privilege.moduleDesc["Create"]?`${privilege.moduleDesc["View"]}, ${privilege.moduleDesc["Create"]}, ${privilege.moduleDesc["Edit"]}, ${privilege.moduleDesc["Full"]}`
						  :`${privilege.moduleDesc["View"]}, ${privilege.moduleDesc["Edit"]}`}`
						}}
                      </div>
                    </el-tooltip>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </Section>
      </div>
      <div class="text-right">
        <BaseButton @click="handleCancel">Cancel</BaseButton>
        <BaseButton type="primary" :disabled="disabled" @click="handleSubmit"
          >Create</BaseButton
        >
      </div>
    </GgPage>
	<gg-Dialog
	  title="Save changes"
	  class="noborder-dialog"
	  :visible.sync="formDialogView.visible"
	  :show-close="true"
	  :before-close="
	    () => {
	      formDialogView.visible = !formDialogView.visible;
	    }
	  "
	>
	  <div class="dialog-wrapper">
	    You have some unsaved changes. Please save them before you continue.
	  </div>
	</gg-Dialog>
    <Snackbar
      :visible.sync="snackbarData.visible"
      :content="snackbarData.content"
    />
  </div>
</template>

<script>
import { Ajax } from "@/common";
export default {
  name: "createRoles",
  components: {},
  watch: {
    userRoleData: {
      deep: true,
      handler: function (res) {
        this.$nextTick(() => {
          let permission = this.formDataPrivilege.some(
            (item) => item.ismodulePermission
          );
          let isName =
            res.fromDataInfo.find((item) => item.key === "name").value !== "" &&
            !this.$refs.name[0].error.show;
          let describ = !this.$refs.describ[0].error.show;
          this.disabled = !(permission && isName && describ);
        });
      },
    },
  },
  computed: {
    userRoleData() {
      const { fromDataInfo, formDataPrivilege } = this;
      return {
        fromDataInfo,
        formDataPrivilege,
      };
    },
    errorObjCom() {
      let obj = {
        name: { show: false, message: "Role name required" },
        describ: { show: false, message: "Character limit of 80" },
      };

      this.fromDataInfo.forEach((item) => {
        if (item.key === "name") {
          if (item.value === "") {
            obj.name.show = true;
          } else if (item.value.length > 40) {
            obj.name.show = true;
            obj.name.message = "Character limit of 40";
          }
        }
        if (item.key === "describ") {
          if (item.value.length > 80) {
            obj.describ.show = true;
          }
        }
      });

      return obj;
    },
  },
  data() {
    return {
		isEdit:true,
		formDialogView: {
		  visible: false,
		},
      fromDataInfo: [
        {
          value: "",
          placeholder: "Role name *",
          key: "name",
        },
        {
          value: "",
          placeholder: "Description",
          key: "describ",
        },
      ],
      disabled: true,
      formDataPrivilege: [
        {
          moduleName: "Staff",
          apps: require("@/assets/images/menu/staff.svg"),
          ismodulePermission: false,
          privilegeList: [
            {
              name: "Staff",
              optionsVal: "View",
              options: ["View", "Create", "Edit", "Full"],
              moduleDesc: {
                View: "View all staff records for a location",
                Create: "add a new staff record",
                Edit: "edit a staff record, reset staff password",
                Full:
                  "reset staff password, delete, archive and restore staff record",
              },
            },
          ],
        },
        {
          moduleName: "Students",
          apps: require("@/assets/images/menu/students.svg"),
          ismodulePermission: false,
          privilegeList: [
            {
              name: "Students",
              optionsVal: "View",
              options: ["View", "Create", "Edit", "Full"],
              moduleDesc: {
                View: "View all students records for a location",
                Create: "enroll a new student record",
                Edit: "edit a student record",
                Full:
                  "delete,defer a student, return a student, withdraw a student, enroll a withdrawn student",
              },
            },
            {
              name: "Students contacts",
              optionsVal: "View",
              options: ["View", "Create", "Edit", "Full"],
              moduleDesc: {
                View: "View all contact info of a student",
                Create: "add all kinds of contact to a student",
                Edit: "edit all info of a contact, reset family password",
                Full:
                  "mark as primary contact, delete a contact, invite an added family member to the system, resend the invitation email to invite the family member, disconnect a family account with the student",
              },
            },
          ],
        },
        {
          moduleName: "People",
          apps: require("@/assets/images/menu/people.svg"),
          ismodulePermission: false,
          privilegeList: [
            {
              name: "People",
              optionsVal: "View",
              options: ["View"],
              moduleDesc: {
                View: "View all people in a location",
              },
            },
          ],
        },
        {
          moduleName: "Classes",
          apps: require("@/assets/images/menu/classes.svg"),
          ismodulePermission: false,
          privilegeList: [
            {
              name: "Class",
              optionsVal: "View",
              options: ["View", "Create", "Edit", "Full"],
              moduleDesc: {
                View: "View all class records for a location",
                Create: "create a new class",
                Edit: "edit a class info, manage people in a class",
                Full: "delete a class",
              },
            },
            {
              name: "Promote classes",
              optionsVal: "View",
              options: ["View", "Create", "Edit", "Full"],
              moduleDesc: {
                View: "View all class records for a location",
                Create: "",
                Edit: "promote students in a class to another one",
                Full: "",
              },
            },
          ],
        },
        {
          moduleName: "Attendance",
          apps: require("@/assets/images/menu/attendance.svg"),
          ismodulePermission: false,
          privilegeList: [
            {
              name: "Attendance",
              optionsVal: "View",
              options: ["View", "Create", "Edit", "Full"],
              moduleDesc: {
                View: "View student attendance records of a class",
                Create: "add student's attendance record",
                Edit: "edit student's attendance record",
                Full: "delete student attendance record",
              },
            },
			{
			  name: "Check-in Kiosk",
			  optionsVal: "View",
			  options: ["View", "Create", "Edit", "Full"],
			  moduleDesc: {
			    View: "View all check-in record",
			    Create: "check in/out student",
			    Edit: "check in/out student",
			    Full: "check in/out student",
			  }, 
			}
          ],
        },
        {
          moduleName: "Commbook",
          apps: require("@/assets/images/menu/commbook.svg"),
          ismodulePermission: false,
          privilegeList: [
            {
              name: "Messages",
              optionsVal: "View",
              options: ["View", "Create", "Edit", "Full"],
              moduleDesc: {
                View: "View all messages",
                Create: "create a new message and send to seleted parents",
                Edit: "reply a message, edit a message created by own",
                Full: "delete a message created by own",
              },
            },
          ],
        },
        {
          moduleName: "Moments",
          apps: require("@/assets/images/menu/moments.svg"),
          ismodulePermission: false,
          privilegeList: [
            {
              name: "Manage media",
              optionsVal: "View",
              options: ["View", "Create", "Edit", "Full"],
              moduleDesc: {
                View: "View all media in a class",
                Create: "upload media",
                Edit: "edit an unshared photo, including edit photo info and add/remove favourite",
                Full: "delete an unshared media and download photo",
              },
            },
            {
              name: "Share media",
              optionsVal: "View",
              options: ["View", "Create", "Edit", "Full"],
              moduleDesc: {
                View: "View all media in sharing",
                Create: "share selected media with parents",
                Edit: "undo a photo submission",
                Full: "approve or recall a photo sharing",
              },
            },
            {
              name: "Manage albums",
              optionsVal: "View",
              options: ["View", "Create", "Edit", "Full"],
              moduleDesc: {
                View: "View all albums in a class",
                Create: "create albums",
                Edit: "edit an album, including rename the album and change album cover. manage the photo in the album, including add photo to the album and remove photo from the album",
                Full: "delete an album",
              },
            },
          ],
        },
        {
          moduleName: "Announcements",
          apps: require("@/assets/images/menu/announcement.svg"),
          ismodulePermission: false,
          privilegeList: [
            {
              name: "Announcements",
              optionsVal: "View",
              options: ["View", "Create", "Edit", "Full"],
              moduleDesc: {
                View: "View all announcements",
                Create: "add a new announcement",
                Edit: "edit an announcement",
                Full:
                  "post, delete, recall an announcement, archive, restore an announcement",
              },
            },
          ],
        },
		{
		  moduleName: "Journal",
		  apps: require("@/assets/images/menu/journal.svg"),
		  ismodulePermission: false,
		  privilegeList: [
		    {
		      name: "Manage journals",
		      optionsVal: "View",
		      options: ["View", "Create", "Edit", "Full"],
		      moduleDesc: {
		        View: "View and preview all journals",
		        Create: "create journals",
		        Edit: "edit unshared journals, print and download journals",
		        Full:
		          "delete an unshared journal",
		      },
		    },
			{
			  name: "Share journals",
			  optionsVal: "View",
			  options: ["View", "Create", "Edit", "Full"],
			  moduleDesc: {
			    View: "View all journals in posted",
			    Create: "share selected journal with parents",
			    Edit: "undo a journal submission",
			    Full:
			      "approve or recall a journal submission",
			  },
			},
		  ],
		},
      ],
      snackbarData: {
        visible: false,
        content: "",
      },
    };
  },
  methods: {
    handleSnack(content) {
      this.snackbarData.content = content;
      this.snackbarData.visible = true;
    },
    handleCancel() {
		this.isEdit=false
      this.$router.push("/companyRoles");
    },
    handleSubmit() {
      let rolePermissionList = [],
        obj = {};
      this.formDataPrivilege.forEach((item) => {
        if (item.ismodulePermission) {
          let permission = [],
            obj = {};
          item.privilegeList.forEach((item2) => {
            let option = {};
            option = {
              lable: item2.name,
              value: item2.optionsVal,
            };
            permission.push(option);
          });

          obj = {
            moduleName: item.moduleName,
            hasModule: item.ismodulePermission ? "1" : "0",
            permissions: JSON.stringify(permission),
          };
          rolePermissionList.push(obj);
        }
      });

      this.fromDataInfo.forEach((item) => {
        obj[item.key] = item.value;
      });

      obj.rolePermissionList = rolePermissionList;

      let params = Object.assign({}, obj, {
        userId: this.$store.state.user.userId,
      });

      Ajax.post("/usermanage/role/save", params).then((res) => {
        if (res.code === "0000") {
			this.isEdit=false
          this.$router.push("/companyRoles");
        } else {
          this.handleSnack(res.message);
        }
      });
    },
  },
  created() {},
  beforeRouteLeave(to, form, next) {
    if (this.isEdit) {
      this.formDialogView.visible = true;
    } else {
      next();
    }
  },
};
</script>
<style lang="less">
.section-nopad .section-main {
  margin-top: 0 !important;
  border-bottom: 1px solid #e0e0e0;
}
.text-reminder {
  margin-top: 40px;
  font-size: 12px;
  font-family: Roboto;
  color: #b4b4b4;
}
</style>
<style lang='less' scoped>
//@import url(); 引入公共css类
/deep/ .roles-content{
  height: calc(100% - 112px);
  overflow-y: auto;
}
</style>
